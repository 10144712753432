<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/oilfieldList">油田管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/oilfieldList">油田列表 </a></slot></span
          >
          <span slot="l"><slot>> 修改信息 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="oilForm"
            :rules="rules"
            ref="oilForm"
            label-width="150px"
            class="oilForm"
          >
            <el-form-item label="序号：">
              <p>{{ oilForm.companyid }}</p>
            </el-form-item>
            <el-form-item label="油田全名：" prop="name">
              <el-input v-model="oilForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="油田简称：" prop="nickname">
              <el-input
                v-model="oilForm.nickname"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="油田位置：" prop="type">
              <el-select v-model="oilForm.type" placeholder="请选择">
                <el-option label="省市县" :value="1"></el-option>
                <el-option label="详细地址" :value="2"></el-option>
                <el-option label="经纬度" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="油田省市县："
              v-if="oilForm.type == 1 && addressProps.show"
            >
              <el-cascader
                v-model="selectAddress"
                :props="addressProps"
                clearable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="油田详细地址：" v-show="oilForm.type == 2">
              <el-input
                v-model="oilForm.address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="油田经度：" v-show="oilForm.type == 3">
              <el-input
                v-model="oilForm.longitude"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="油田纬度：" v-show="oilForm.type == 3">
              <el-input
                v-model="oilForm.latitude"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="地区号：">
              <p>{{ oilForm.adcode }}</p>
            </el-form-item>
            <el-form-item label="所属客户公司：">
              <p>{{ oilForm.company_info }}</p>
            </el-form-item>
            <!-- <el-form-item label="油田负责人：" prop="manager_info">
              <el-input
                v-model="oilForm.manager_info"
                placeholder="请输入"
                @change="userGet"
              ></el-input>
              <span class="tips">注：输入手机号验证人名</span>
            </el-form-item> -->
            <el-form-item label="创建时间：">
              <p>{{ oilForm.date }}</p>
            </el-form-item>
            <el-form-item>
              <el-button class="reset" @click="resetForm">重置</el-button>
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('oilForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import OilfieldJS from 'network/oilfield/oilfield.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 2,
      params: {},
      oilForm: {
        name: '',
        nickname: '',
        type: '',
        province: '',
        city: '',
        country: '',
        address: '',
        longitude: '',
        latitude: '',
        companyid: ''
      },
      rules: {
        name: [{ required: true, message: '请输入油田名称', trigger: 'blur' }],
        nickname: [
          { required: true, message: '请输入油田简称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择油田位置', trigger: 'change' }
        ],
        companyid: [
          { required: true, message: '请选择公司', trigger: 'change' }
        ]
      },
      selectAddress: [],
      addressProps: {
        show: false,
        lazy: true,
        lazyLoad: this.typeLazyLoad
      }
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 修改并提交用户表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.oilForm.province = this.selectAddress[0]
          this.oilForm.city = this.selectAddress[1]
          this.oilForm.county = this.selectAddress[2]
          this.oilfieldUpdate()
        }
      })
    },
    resetForm() {
      this.oilfieldGet()
      this.getCompanyList()
    },
    // 动态加载省市县
    typeLazyLoad(node, resolve) {
      const { level } = node
      // console.log('level', level)
      if (level == 0) {
        const params = { session_id: this.$store.state.loginInfo.session_id }
        OilfieldJS.province(params).then((res) => {
          if (res.code == 0) {
            const nodes = []
            for (const i of res.province_list) {
              nodes.push({ label: i, value: i, leaf: level >= 2 })
            }
            resolve(nodes)
          } else {
            this.$message.closeAll()
            this.$message({
              showClose: true,
              duration: 1500,
              message: res.msg,
              type: 'warning'
            })
          }
        })
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
      }
      if (level == 1) {
        const params = {
          session_id: this.$store.state.loginInfo.session_id,
          province: node.value
        }
        OilfieldJS.city(params).then((res) => {
          if (res.code == 0) {
            const nodes = []
            for (const i of res.city_list) {
              nodes.push({ label: i, value: i, leaf: level >= 2 })
            }
            resolve(nodes)
          } else {
            this.$message.closeAll()
            this.$message({
              showClose: true,
              duration: 1500,
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }
      if (level == 2) {
        const params = {
          session_id: this.$store.state.loginInfo.session_id,
          province: node.parent.value,
          city: node.value
        }
        OilfieldJS.county(params).then((res) => {
          if (res.code == 0) {
            const nodes = []
            for (const i of res.county_list) {
              nodes.push({ label: i, value: i, leaf: level >= 2 })
            }
            resolve(nodes)
          } else {
            this.$message.closeAll()
            this.$message({
              showClose: true,
              duration: 1500,
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }
    },
    /**
     * 网络请求
     */
    oilfieldUpdate() {
      this.oilForm.session_id = this.params.session_id
      OilfieldJS.oilfieldUpdate(this.oilForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.$router.replace('/oilfieldList')
          }, 500)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldGet() {
      this.params.session_id = this.$store.state.loginInfo.session_id
      this.params.oilfieldid = this.$route.query.oilfieldid
      OilfieldJS.oilfieldGet(this.params).then((res) => {
        if (res.code == 0) {
          this.oilForm = res.oilfield_data
          this.selectAddress[0] = res.oilfield_data.province
          this.selectAddress[1] = res.oilfield_data.city
          this.selectAddress[2] = res.oilfield_data.county
          this.addressProps.show = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
    /**
     * 其它方法
     */
  },
  mounted() {
    this.oilfieldGet()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  },
  beforeDestroy() {
    this.$message.closeAll()
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .oilForm {
      padding: 85px 0 0 72px;
      width: auto;
      height: auto;
      .tips {
        margin-left: 17px;
        width: auto;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(1, 158, 229, 0.88);
      }
      .reset {
        margin-left: 90px;
        border-radius: 0;
      }
      .submit {
        margin-left: 20px;
        border-radius: 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 28px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 42px;
      }
    }
  }
}
</style>
